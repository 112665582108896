import type {
  BreakingGroup,
  MhluHomepageGroup,
  MhluSectionpageGroup,
  OpeningGroup,
  OpinionGroup,
  HtmlGroup,
  AdGroup,
} from '../../base';
import type { PickTypenamed } from '../../utils/typenamed';
import { RegularGroup, VisualStoriesGroup, LifestyleGroup, SponsoredGroup } from '../../base';

import type { HtmlBlockFragment, OnArticleList, TeaserFragment, RssListContentFragment } from './section-teasers';

export type HtmlTeaserFragment = Omit<TeaserFragment, 'content'> & HtmlBlockFragment;
export type RssListTeaserFragment = Omit<TeaserFragment, 'content' | 'trackingData'> & RssListContentFragment;
export type TeaserOrHtmlBlock = TeaserFragment | RssListTeaserFragment | HtmlTeaserFragment;
export function isHtmlTeaserFragment(fragment: TeaserOrHtmlBlock): fragment is HtmlTeaserFragment {
  return fragment && fragment.content.__typename === 'HtmlBlock';
}
export function isRssArticleListFragment(fragment: TeaserOrHtmlBlock): fragment is RssListTeaserFragment {
  return fragment && fragment.content.__typename === 'RssArticleList';
}

type OnRegularGroup = PickTypenamed<RegularGroup, 'type' | 'areaNames'> & {
  groupOptions: Array<Pick<RegularGroup['groupOptions'][number], 'key' | 'value'>>;
  groupArea: Array<PickTypenamed<RegularGroup['groupArea'][number]> & TeaserOrHtmlBlock>;
};

type OnVisualStoriesGroup = PickTypenamed<VisualStoriesGroup, 'type' | 'areaNames'> & {
  groupOptions: Array<Pick<VisualStoriesGroup['groupOptions'][number], 'key' | 'value'>>;
  groupArea: Array<PickTypenamed<VisualStoriesGroup['groupArea'][number]> & TeaserOrHtmlBlock>;
};

type OnLifestyleGroup = PickTypenamed<LifestyleGroup, 'type' | 'areaNames'> & {
  groupOptions: Array<Pick<LifestyleGroup['groupOptions'][number], 'key' | 'value'>>;
  groupArea: Array<PickTypenamed<LifestyleGroup['groupArea'][number]> & TeaserOrHtmlBlock>;
};

type OnSponsoredGroup = PickTypenamed<SponsoredGroup, 'type' | 'areaNames'> & {
  groupOptions: Array<Pick<SponsoredGroup['groupOptions'][number], 'key' | 'value'>>;
  groupArea: Array<PickTypenamed<SponsoredGroup['groupArea'][number]> & TeaserOrHtmlBlock>;
};

type OnOpeningGroup = PickTypenamed<OpeningGroup, 'type' | 'areaNames'> & {
  groupOptions: Array<Pick<OpeningGroup['groupOptions'][number], 'key' | 'value'>>;
  newsArea: Array<PickTypenamed<OpeningGroup['newsArea'][number]> & TeaserFragment>;
  newsAreaOptions: Array<Pick<OpeningGroup['newsAreaOptions'][number], 'key' | 'value'>>;
  listArea: Array<PickTypenamed<OpeningGroup['listArea'][number]> & TeaserFragment>;
  listAreaOptions: Array<Pick<OpeningGroup['listAreaOptions'][number], 'key' | 'value'>>;
};

type OnBreakingGroup = PickTypenamed<BreakingGroup, 'type' | 'areaNames'> & {
  groupOptions: Array<Pick<BreakingGroup['groupOptions'][number], 'key' | 'value'>>;
  groupArea: Array<PickTypenamed<BreakingGroup['groupArea'][number]> & TeaserFragment>;
};

type OnOpinionGroup = PickTypenamed<OpinionGroup, 'type' | 'areaNames'> & {
  groupOptions: Array<Pick<OpinionGroup['groupOptions'][number], 'key' | 'value'>>;
  groupArea: Array<PickTypenamed<OpinionGroup['groupArea'][number]> & TeaserFragment>;
};

type OnHtmlGroup = PickTypenamed<HtmlGroup, 'type' | 'areaNames'> & {
  groupOptions: Array<Pick<HtmlGroup['groupOptions'][number], 'key' | 'value'>>;
  groupArea: Array<PickTypenamed<HtmlGroup['groupArea'][number]> & HtmlBlockFragment>;
};

type OnAdGroup = PickTypenamed<AdGroup, 'type'> & {
  groupOptions: Array<Pick<AdGroup['groupOptions'][number], 'key' | 'value'>>;
};

export type OnMhluHomepageGroup = PickTypenamed<MhluHomepageGroup> & {
  classifieds: Array<
    PickTypenamed<MhluHomepageGroup['classifieds'][number]> &
      (
        | OnRegularGroup
        | OnLifestyleGroup
        | OnVisualStoriesGroup
        | OnSponsoredGroup
        | OnOpeningGroup
        | OnBreakingGroup
        | OnOpinionGroup
        | OnHtmlGroup
      )
  >;
  groupOptions: Array<Pick<MhluHomepageGroup['groupOptions'][number], 'key' | 'value'>>;
  news: Array<
    PickTypenamed<MhluHomepageGroup['news'][number]> &
      (
        | OnRegularGroup
        | OnLifestyleGroup
        | OnVisualStoriesGroup
        | OnSponsoredGroup
        | OnOpeningGroup
        | OnBreakingGroup
        | OnOpinionGroup
        | OnHtmlGroup
      )
  >;
  newsflow1: Array<TeaserFragment<OnArticleList>>;
  newsflow2: Array<TeaserFragment<OnArticleList>>;
  nonContent: Array<PickTypenamed<MhluHomepageGroup['nonContent'][number]> & OnAdGroup>;
  topStories: Array<
    PickTypenamed<MhluHomepageGroup['topStories'][number]> &
      (
        | OnRegularGroup
        | OnLifestyleGroup
        | OnVisualStoriesGroup
        | OnSponsoredGroup
        | OnOpeningGroup
        | OnBreakingGroup
        | OnOpinionGroup
        | OnHtmlGroup
      )
  >;
};

export type OnMhluSectionpageGroup = PickTypenamed<MhluSectionpageGroup> & {
  groupOptions: Array<Pick<MhluSectionpageGroup['groupOptions'][number], 'key' | 'value'>>;
  news: Array<(PickTypenamed<MhluSectionpageGroup['news'][number]> & TeaserFragment) | OnHtmlGroup>;
};

export function isSectionHtmlGroup(content: OnMhluSectionpageGroup['news'][number]): content is OnHtmlGroup {
  return !!content && content.__typename === 'HtmlGroup';
}

export type MHLULayoutsFragment = OnMhluHomepageGroup | OnMhluSectionpageGroup;
